import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ContactMe from "../components/contactMe"
import MainHero from "../components/mainHero"
import { FiMail, FiPhone, FiMapPin } from "react-icons/fi"
import contactStyles from "../styles/contact.module.scss"
import SEO from "../components/SEO"

const Contact = ({
  data: {
    content: { frontmatter },
  },
}) => {
  const renderBox = (icon, title, content) => {
    return (
      <div className="column is-half has-text-centered is-size-3">
        <div className={`box ${contactStyles.box}`}>
          {icon}
          <h4
            className="has-text-primary is-size-3-tablet is-size-4"
            style={{ paddingBottom: "1rem" }}
          >
            {title}
          </h4>
          <p className="has-text-primary is-size-6 is-size-4-tablet">
            {content}
          </p>
        </div>
      </div>
    )
  }
  const renderOfficeImages = (image1, image2) => {
    return (
      <div
        className="column has-text-centered is-size-3"
        style={{ margin: "auto" }}
      >
        <div className={`box ${contactStyles.box}`}>
          <h4
            className="has-text-primary is-size-3-tablet is-size-4"
            style={{ paddingBottom: "1rem" }}
          >
            My Office
          </h4>
          <p className="has-text-primary is-size-6-mobile">
            Haynes is immediately north of Walgreens on Woodward. The building
            entrance is on the east side, facing Adams Road. There is free,
            easily accessible parking all around the building.
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Img
              fixed={image1}
              style={
                {
                  // display: "block",
                  // margin: "auto",
                  // padding: "1rem",
                }
              }
            />
            <Img
              fixed={image2}
              style={
                {
                  // display: "block",
                  // margin: "auto",
                  // padding: "1rem",
                }
              }
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <Layout>
      <SEO title="Contact Us | Sydney Reiter | Birmingham, MI" />
      <MainHero
        heroImage={frontmatter.hero_image}
        title={frontmatter.title}
        subTitle={frontmatter.subTitle}
        height="is-medium"
        crop="center"
      />
      <section className="section">
        <div className="container">
          <div
            style={{
              maxWidth: "850px",
              margin: "auto",
            }}
          >
            <h2 className="title has-text-centered">
              {frontmatter.intro_title}
            </h2>
            <p>{frontmatter.intro_content}</p>
            <form
              name="contact"
              method="post"
              netlify-honeypot="bot-field"
              data-netlify="true"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label htmlFor="firstName" className="label">
                      First Name
                    </label>
                    <div className="control">
                      <input
                        id="firstName"
                        name="firstName"
                        className="input"
                        type="text"
                        placeholder="Your First Name"
                      />
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label htmlFor="lastName" className="label">
                      Last Name
                    </label>
                    <div className="control">
                      <input
                        id="lastName"
                        name="lastName"
                        className="input"
                        type="text"
                        placeholder="Your Last Name"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label" htmlFor="email">
                      Email
                    </label>
                    <div className="control">
                      <input
                        id="email"
                        name="email"
                        className="input"
                        type="email"
                        placeholder="Your Email"
                      />
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label" htmlFor="phone">
                      Phone Number
                    </label>
                    <div className="control">
                      <input
                        id="phone"
                        name="phone"
                        className="input"
                        type="phone"
                        placeholder="Your Phone Number"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="message">
                  Message
                </label>
                <div className="control">
                  <textarea
                    className="textarea"
                    id="message"
                    name="message"
                    placeholder="Your message"
                  ></textarea>
                </div>
              </div>

              <button className="button is-link is-medium">Send Message</button>
            </form>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns is-multiline is-centered">
            {renderBox(<FiPhone />, "Phone", "248-408-3058")}
            {renderBox(<FiMail />, "Email", "sydneyreiter@gmail.com")}
            {renderBox(
              <FiMapPin />,
              "Address",
              "999 Haynes, Suite 335 Birmingham, Michigan 48009"
            )}
          </div>
          {renderOfficeImages(
            frontmatter.office_image.childImageSharp.fixed,
            frontmatter.building_image.childImageSharp.fixed
          )}
        </div>
      </section>
      <ContactMe />
    </Layout>
  )
}

export default Contact

export const query = graphql`
  {
    content: mdx(frontmatter: { section: { eq: "contact" } }) {
      frontmatter {
        hero_image {
          childImageSharp {
            sizes(maxWidth: 1920) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        office_image {
          childImageSharp {
            fixed(width: 320, height: 240) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        building_image {
          childImageSharp {
            fixed(width: 300, height: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        title
        intro_title
        intro_content
      }
    }
  }
`
